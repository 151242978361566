<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <div class="row">
      <div class="col">
        <b-overlay :show="testLoading">
        <b-card v-if="test">
          <h2>Test Result</h2>
          <hr />
          <template  v-if="test.graded">
            <div class="mt-4">
              <BookSnippet :book="book" :toLink="{name:'book-preview', params:{book_id}}" />
            </div>
            <div class="mt-4 text-center">
              <h5>
                <ThumbIcon /><span class="mx-2">{{encouragement}}</span>
                You got
                <span class="text-success font-weight-bold lead">{{test.score}}</span>
                points.
              </h5>
            </div>
            <div class="mt-4">
              <h6>Total number of questions:<span class="mx-2">{{test.quiz_count}}</span></h6>
              <h6 class="text-success">Number of questions correctly answered:<span class="mx-2">{{correctAnsweredCount}}</span></h6>
              <h6 class="text-danger">Number of questions wrongly answered:<span class="mx-2">{{wrongAnsweredCount}}</span></h6>
              <h6 class="text-warning">Number of questions not answered:<span class="mx-2">{{notAnsweredCount}}</span></h6>
            </div>
            <div class="mt-4">
              <QuizPagination :quizArray="quizzes" :graded="test.graded" :showPrevNext="false" :highlightActive="false" @input="paginationClicked($event)" />
            </div>
          </template>
          <div class="mt-4 text-center" v-else>
            <h6 class="text-danger">This test is not yet graded.</h6>
          </div>
        </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import ThumbIcon from 'mdi-vue/ThumbUpOutline';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import getBookMixin from '@/mixins/getbook';
import BookSnippet from '@/views/public/book/components/BookSnippet.vue';
import QuizPagination from '../components/QuizPagination.vue';

export default {
  name: 'TestResult',
  mixins: [getBookMixin],
  components: {
    BreadCrumb, BookSnippet, ThumbIcon, QuizPagination,
  },

  data() {
    return {
      testLoading: false,
      test_id: this.$route.params.test_id,
      book_id: this.$route.params.book_id,
      test: null,
      book: null,
      quizzes: [],
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books Categories', to: { name: 'book-cat-list' } },
        { text: (this.book ? this.book.title : this.book_id), to: { name: 'book-preview', params: { book_id: this.book_id } } },
        { text: 'Questions', to: { name: 'test-question', params: { book_id: this.book_id, test_id: this.test_id } } },
        { text: 'Test Result', active: true },
      ];
    },
    encouragement() {
      const encouragements = [
        'Well done!',
        'Awesome!',
        'Good job!',
      ];
      return encouragements[(Math.floor(Math.random() * encouragements.length))];
    },
    correctAnsweredCount() {
      return (this.quizzes && this.quizzes.length) ? this.quizzes.filter(x => x.correct_answer === true).length : 0;
    },
    wrongAnsweredCount() {
      return (this.quizzes && this.quizzes.length) ? this.quizzes.filter(x => (x.correct_answer === false && x.user_answers && x.user_answers.length)).length : 0;
    },
    notAnsweredCount() {
      return (this.test) ? (this.test.quiz_count - this.correctAnsweredCount - this.wrongAnsweredCount) : 0;
    },
  },

  methods: {
    getTest() {
      if (this.book_id && this.test_id) {
        this.testLoading = true;
        this.$store.dispatch('test/getTestById', this.test_id).then((test) => {
          this.test = Object.assign({}, test);
          this.quizzes = test.quizzes.slice(0);
        }).catch(() => {}).finally(() => {
          this.testLoading = false;
        });
      }
    },
    paginationClicked(currentItem) {
      this.$router.push({ name: 'test-question', params: { book_id: this.book_id, test_id: this.test_id, question_no: currentItem } });
    },
  },

  created() {
    this.getTest();
  },
};
</script>
